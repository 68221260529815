import React from "react";
import {Row, Col} from 'antd'
import pdfFile from "../../pdfFiles/pdf3.pdf";
import pdfFile1 from '../../pdfFiles/pdf4.pdf'
import { AiFillFilePdf } from "react-icons/ai";

function EssentialFactsPage() {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Существенные факты</h1>
      <Row className="row">
      <Col flex="1 0 25%" className="column-pdf-text">
      <a href={pdfFile} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>ИНФОРМАЦИЯ О СУЩЕСТВЕННОМ ФАКТЕ №6</p>
      </Col>
      <Col flex="1 0 25%" className="column-pdf-text"><a href={pdfFile1} target="_blank" rel="pdf-file noreferrer">
        <AiFillFilePdf className="pdf-file" style={{color: '#E93131'}}/>
      </a>
      <p className="pdf-text" style={{fontWeight: 500, fontSize: '18px'}}>ИНФОРМАЦИЯ О СУЩЕСТВЕННОМ ФАКТЕ №36</p>
      </Col>
    </Row>
    </div>
  );
}

export default EssentialFactsPage;
